function createSphereAnimation(sphereEl) {
  var spherePathEls = sphereEl.querySelectorAll('.sphere path');
  var pathLength = spherePathEls.length;
  var animations = [];

  var sphereId = sphereEl.getAttribute('data-id'); // Get the sphere's ID

  var defaultColor = 'rgba(255, 75, 75, 1)'; // Default color

  if (sphereId === '1') {
    defaultColor = 'rgba(255, 240, 0, 1)'; // Yellow color for sphere with ID '1'
  } else if (sphereId === '2') {
    defaultColor = 'rgba(45, 85, 255, 1)'; // Blue color for sphere with ID '2'
  } else if (sphereId === '3') {
    defaultColor = 'rgba(243, 112, 55, 1)'; // Orange color for sphere with ID '3'
  }

  var breathAnimation = anime({
    begin: function () {
      for (var i = 0; i < pathLength; i++) {
        animations.push(
          anime({
            targets: spherePathEls[i],
            stroke: {
              value: [defaultColor, 'rgb(246, 246, 246)'],
              duration: 500,
            },
            translateX: [2, -4],
            translateY: [2, -4],
            easing: 'easeOutQuad',
            autoplay: false,
          })
        );
      }
    },
    update: function (ins) {
      animations.forEach(function (animation, i) {
        var percent =
          (1 - Math.sin(i * 0.35 + 0.0022 * ins.currentTime)) / 2;
        animation.seek(animation.duration * percent);
      });
    },
    duration: Infinity,
    autoplay: false,
  });

  var introAnimation = anime.timeline({
    autoplay: false,
  }).add(
    {
      targets: spherePathEls,
      strokeDashoffset: {
        value: [anime.setDashoffset, 0],
        duration: 3900,
        easing: 'easeInOutCirc',
        delay: anime.stagger(190, { direction: 'reverse' }),
      },
      duration: 2000,
      delay: anime.stagger(60, { direction: 'reverse' }),
      easing: 'linear',
    },
    0
  );

  var shadowAnimation = anime(
    {
      targets: '#sphereGradient',
      x1: '25%',
      x2: '25%',
      y1: '0%',
      y2: '75%',
      duration: 30000,
      easing: 'easeOutQuint',
      autoplay: false,
    },
    0
  );

  function init() {
    introAnimation.play();
    breathAnimation.play();
    shadowAnimation.play();
  }

  init();
}

var sphereElements = document.querySelectorAll('.container__sphere');
sphereElements.forEach(function (sphereEl) {
  createSphereAnimation(sphereEl);
});
